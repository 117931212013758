import { acceptHMRUpdate, defineStore } from 'pinia'
import type { GetVendorsType, VendorType } from '@/types/vendor.type.js'
import CustomError from '@/js/custom-error.js'
import { Bugsnag } from '@/main.js'
import { Event } from '@bugsnag/js'
import type { VendorContactType } from '@/types/vendor-contact.type'
import type {
  VendorDeliveryDayType,
  VendorOTCDeliveryDayType
} from '@/types/vendor-delivery-day.type'

const defaultCurrentVendorState: VendorType = {
  id: 0,
  master_id: 0,
  name: '',
  account_number: '',
  vip_account_code: '',
  vendor_notes: '',
  venue_id: 0,
  warehoused: 0,
  create_date: 0,
  sends_cutoff_notification: 0,
  vip_distributor_id: '',
  vip_distributor_isv_code: '',
  vip_distributor_source_code: '',
  vip_retailer_id: '',
  vip_sm_code: '',
  amount_owed: 0,
  otc_enabled: false,
  contacts: [] as VendorContactType[],
  deliveryDays: [] as VendorDeliveryDayType[],
  otcDeliveryDays: [] as VendorOTCDeliveryDayType[]
}
interface State {
  vendors: VendorType[]
  currentVendor: VendorType
}

export const useVendorsStore = defineStore('vendors', {
  state: (): State => {
    return {
      vendors: [],
      currentVendor: defaultCurrentVendorState
    }
  },
  getters: {
    owedVendors(state): VendorType[] {
      return state.vendors.filter((v) => v.invoices?.some((invoice) => invoice.balance > 0))
    }
  },
  actions: {
    async getVendors() {
      const vendors: GetVendorsType = await $.get('/vendors')
      this.vendors = vendors
    },
    async getVendorsInvoiceHistory(retailerId: string, context: string) {
      try {
        // refresh the list of vendors
        await this.getVendors()

        // load all of the invoice history for the retailer
        const addInvoicesPromises: any[] = []
        this.vendors.forEach((vendor) => {
          addInvoicesPromises.push(
            $.post('/vip/order-request-service/retailer/add-invoices', {
              account_code: vendor.vip_account_code,
              source_code: vendor.vip_distributor_source_code
            })
          )
        })
        Promise.allSettled(addInvoicesPromises).then((results) => {
          const failedInvoices: PromiseRejectedResult[] = []
          results.forEach((result) => {
            if (result.status === 'rejected') {
              failedInvoices.push(result)
            }
          })

          if (failedInvoices.length) {
            Bugsnag.notify(
              new CustomError(
                'POST /vip/order-request-service/retailer/add-invoices',
                new Error(
                  `${failedInvoices.length} / ${addInvoicesPromises.length} failed to process`
                )
              ),
              function (event: Event) {
                event.severity = 'info'
                event.context = context
                event.addMetadata('request', {
                  retailerId,
                  body: failedInvoices.map((failedInvoice) => {
                    failedInvoice.reason
                  }),
                  status: failedInvoices.map((failedInvoice) => failedInvoice.status),
                  method: 'POST'
                })
              }
            )
          }
        })
      } catch (error) {
        console.log('Failed to retrieve vendors', error)
      }
    },
    async getCurrentVendor(id: number) {
      const vendorData: VendorType = await $.get(`/vendors?id=${id}`)
      this.currentVendor = vendorData
      this.currentVendor.contacts.forEach((contact) => {
        contact.checked = false
      })
    },
    clearCurrentVendor() {
      this.currentVendor = defaultCurrentVendorState
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useVendorsStore, import.meta.hot))
}
