<template>
  <div :class="['standalone-link', disabled ? 'disabled' : linkType]" @click="handleClick">
    <i v-if="icon && iconAlignment === IconAlignment.Left" :class="[icon, className]"></i>
    <div :class="className">{{ linkLabel }}</div>
    <i v-if="icon && iconAlignment === IconAlignment.Right" :class="[icon, className]"></i>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { useRouter } from 'vue-router'
import { Icons, IconAlignment, StandaloneLinkTypes } from '@/enums/components.enum'

export default defineComponent({
  name: 'StandaloneLinkComponent',
  props: {
    linkLabel: {
      type: String,
      required: true,
      default: 'Link'
    },
    link: {
      type: String,
      default: null,
      required: false
    },
    linkType: {
      type: String as PropType<StandaloneLinkTypes>,
      default: StandaloneLinkTypes.Default
    },
    icon: {
      type: String as PropType<Icons>,
      default: null,
      required: false
    },
    iconAlignment: {
      type: String as PropType<IconAlignment>,
      default: IconAlignment.Left,
      required: false
    },
    className: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup(props, { emit }) {
    const router = useRouter()

    const handleClick = (event: Event) => {
      event.preventDefault() // Prevent any default behavior
      event.stopPropagation() // Stop the event from propagating
      if (props.disabled) {
        return
      }
      if (props.link) {
        if (props.link.includes('www')) {
          window.open(props.link)
        } else {
          router.push(props.link)
        }
      } else {
        emit('click', event)
      }
    }

    return {
      IconAlignment,
      handleClick
    }
  }
})
</script>

<style scoped>
.standalone-link {
  transition:
    color 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

.standalone-link:hover {
  transition:
    color 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}

.standalone-link {
  align-items: center;
  gap: 4px;
  display: flex;
  font-weight: 400;
  cursor: pointer;
}

.standalone-link,
.standalone-link i {
  color: #0074dd; /*interactive-default*/
}

.standalone-link:hover,
.standalone-link:hover i {
  color: #0057a6; /*interactive-hover*/
  text-decoration: none;
}

.standalone-link:active,
.standalone-link:active i {
  color: #003a6f; /*interactive-pressed*/
}

.standalone-link.interactive-critical,
.standalone-link.interactive-critical i {
  color: #ca3534; /* $interactive-critical */
}

.standalone-link.interactive-critical:hover,
.standalone-link.interactive-critical:hover i {
  color: #7e2121; /* $interactive-critical-hover */
}

.standalone-link.interactive-critical:active,
.standalone-link.interactive-critical:active i {
  color: #4c1414; /* $interactive-critical-pressed */
}

.standalone-link .muted,
.standalone-link .muted i {
  color: #0c1c19 !important;
}

.standalone-link .muted:hover {
  text-decoration: underline !important;
}

.standalone-link.disabled {
  color: var(--color-text-subdued) !important;
  cursor: wait;
}
</style>
