export type VenueType = {
  id: number
  unique_id: string
  organization_id: number
  name: string
  default_volume: string
  default_weight: string
  create_date: number
  currency: string
  time_zone: string
  address1: string
  address2: string
  suspended: number
  price_level: number
  rating: number
  google_place_id: string | null
  city: string
  zipcode: string
  type: number
  phone: string
  website: string
  inactive: number
  state_abbrev: string
  state_name: string
  state_id: number
  country_name: string
  country_id: number
  verified_business: number
  created_by: number
  last_updated: string
  vip_retailer_id: string
  should_verify: boolean
  vip_retailers: string[]
  vip_distributors: string[]
  vip_verification_status: string
  vip_verification_submitted_timestamp: string | null
  vip_verified_timestamp: string | null
  vip_unable_to_verify_timestamp: string | null
  vip_verified_by: number | null
  vip_verification_distributor_id: string | null
  vip_verification_invoice_number: string | null
  vip_verification_invoice_date: string | null
  last_didbuy_check: number | null
  didbuy_build_missing: number
  pos: {
    status: string
    has_location: number
    pos_type_id: number
    pos_type_name: string
    pos_type_logo: string
    pos_type_status: number
    create_date: number
    created_by: number
    modified_date: number
    modified_by: number
    sync_date: number
    is_expired?: boolean
  }
  warehoused: number
}

export enum VipVerificationStatusesEnum {
  UNVERIFIED = 'UNVERIFIED',
  UNVERIFIED_WITH_ERROR = 'UNVERIFIED_WITH_ERROR',
  VERIFIED = 'VERIFIED',
  NO_MATCHING_GOOGLE_PLACE_ID_FOUND = 'NO_MATCHING_GOOGLE_PLACE_ID_FOUND',
  NO_MATCHING_RETAILER_FOUND = 'NO_MATCHING_RETAILER_FOUND',
  NO_MATCHING_RETAILER_FOUND_NO_RESULTS = 'NO_MATCHING_RETAILER_FOUND_NO_RESULTS',
  NO_MATCHING_DISTRIBUTOR_FOUND = 'NO_MATCHING_DISTRIBUTOR_FOUND',
  NO_MATCHING_DISTRIBUTOR_FOUND_NO_RESULTS = 'NO_MATCHING_DISTRIBUTOR_FOUND_NO_RESULTS'
}
