<template>
  <div class="dual-calendar d-flex">
    <div class="dual-calendar-input d-flex flex-grow-1 w-100">
      <DatePickerComponent
        v-model:modelValue="internalStartDate"
        :showTimeSelection="showTimeSelection"
        :dateOptions="dateOptions"
        :highlight="highlightEndDate"
        :maxDate="maxStartDate"
        dualPosition="left"
        classNames="flex-grow-1"
      />
      <div class="divider"></div>
      <DatePickerComponent
        v-model:modelValue="internalEndDate"
        :showTimeSelection="showTimeSelection"
        :dateOptions="dateOptions"
        :highlight="highlightStartDate"
        :minDate="minEndDate"
        dualPosition="right"
        classNames="flex-grow-1"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'
import { addDays } from 'date-fns/addDays'
import DatePickerComponent from '@/components/DatePickerComponent.vue'

export default defineComponent({
  components: {
    DatePickerComponent
  },
  name: 'Dual Date Picker Component',
  props: {
    showTimeSelection: {
      type: Boolean,
      default: false,
      required: false
    },
    startDate: {
      required: true,
      type: [Number, null]
    },
    endDate: {
      required: true,
      type: [Number, null]
    }
  },
  emits: ['update:startDate', 'update:endDate'],
  setup(props, { emit }) {
    const internalStartDate = ref(props.startDate)
    const internalEndDate = ref(props.endDate)
    const highlightStartDate = ref([new Date()])
    const highlightEndDate = ref([new Date()])
    const minEndDate = ref(new Date())
    const maxStartDate = ref(new Date())

    watch(internalEndDate, (newValue) => {
      if (newValue) {
        emit('update:endDate', newValue)
        highlightEndDate.value = [addDays(new Date(newValue * 1000), 0)]
        maxStartDate.value = addDays(new Date(newValue * 1000), 0)
      } else {
        emit('update:endDate', null)
      }
    })

    watch(internalStartDate, (newValue) => {
      if (newValue) {
        emit('update:startDate', newValue)
        highlightStartDate.value = [addDays(new Date(newValue * 1000), 0)]
        minEndDate.value = addDays(new Date(newValue * 1000), 0)
      } else {
        emit('update:startDate', null)
      }
    })

    const dateOptions = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }

    return {
      internalStartDate,
      internalEndDate,
      highlightStartDate,
      highlightEndDate,
      minEndDate,
      maxStartDate,
      dateOptions
    }
  }
})
</script>

<style scoped>
.dual-calendar {
  border-color: #dbdfdf;
  border-style: solid;
  border-radius: 3px;
  border-width: 1px;
  box-shadow: 0px 1px 1px 0px rgba(219, 223, 223, 0.75);
  align-items: center;
}

.dual-calendar-input {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
}

.dual-calendar .dual-calendar-input .datepicker-input {
  border: none !important;
  box-shadow: none;
  display: flex;
  align-items: center;
}

.divider {
  margin-top: auto;
  margin-bottom: auto;
  height: 20px;
  width: 1px;
  background-color: #dbdfdf;
}
</style>
