import moment from 'moment'
import type { App } from 'vue'

export default {
  install(app: App, options?: any) {
    app.config.globalProperties.$utils = {
      formatQuantity(number, defaultValue = '') {
        const output = Math.floor(number * 100) / 100
        if (isNaN(output)) {
          return defaultValue
        } else {
          return output
        }
      },
      formatNumber(number) {
        const parts = String(number).split('.')
        if (parts[1]) {
          return parts[0] + '.' + parts[1].substring(0, 2)
        } else {
          return number
        }
      },
      formatCurrency(amount = 0, currency = '', nullValue = false) {
        nullValue = nullValue || false
        if (!amount && nullValue) {
          return ''
        }
        if (!amount && !nullValue) {
          amount = 0
        }
        if (!currency) {
          currency = 'USD'
        }
        return new Intl.NumberFormat('bestfit', {
          style: 'currency',
          currency
        }).format(amount)
      },
      formatInputCurrency(amount, currency) {
        if (!amount) {
          amount = 0
        }
        if (!currency) {
          currency = 'USD'
        }
        const numAmount =
          typeof amount === 'string' ? parseFloat(amount.replace(/[^0-9\.]/g, '')) : amount
        if (isNaN(numAmount)) {
          return ''
        }
        let decimalLength = 0
        if (typeof amount === 'string') {
          const decimals = amount.split('.')[1]
          if (decimals) {
            decimalLength = decimals.length
            if (decimalLength > 2) {
              decimalLength = 0
            }
          }
        }

        return (
          new Intl.NumberFormat('bestfit', {
            style: 'currency',
            currency,
            minimumFractionDigits: decimalLength,
            maximumFractionDigits: 2
          }).format(numAmount) + (typeof amount === 'string' && amount.endsWith('.') ? '.' : '')
        )
      },
      getValueFromCurrency(currencyString) {
        return parseFloat((currencyString || '').replace(/[^0-9\.]/g, ''))
      },
      formatDate(unixTime) {
        const dateObj = new Date(unixTime * 1000)
        return dateObj.toLocaleDateString()
      },
      formatLongDate(date) {
        //Friday, Apr 26, 2024
        return moment(date, 'YYYY-MM-DD').format('dddd, MMM D, YYYY')
      },
      formatTimeNoSeconds12H(unixTime) {
        //10:12PM
        return moment.unix(unixTime).format('hh:mmA')
      },
      formatTime(unixTime) {
        const dateObj = new Date(unixTime * 1000)
        return dateObj.toLocaleTimeString()
      },
      formatTimeNoSeconds(unixTime) {
        const dateObj = new Date(unixTime * 1000)
        const specificTime = dateObj.toLocaleTimeString()
        return specificTime.replace(/:\d+ /, ' ')
      },
      formatDateTime(unixTime) {
        const dateObj = new Date(unixTime * 1000)
        return dateObj.toLocaleString()
      },
      formatHour(timeString) {
        const splitString = timeString.split(':')
        const hour = +splitString[0]
        let output = hour
        if (hour == 0) {
          output = 12
        } else if (hour > 12) {
          output = hour - 12
        }
        if (hour < 12) {
          output += 'AM'
        } else {
          output += 'PM'
        }

        return output
      },
      /**
       * A function for formatting a Unix timestamp to a specific date/time format.
       *
       * Example usage:
       * unixTime = 1683662940, returns: Tue, May 9, 2023, 04:09 PM
       * @param {*} unixTime - The Unix timestamp to format.
       * @returns - A formatted date as a string.
       */
      formatDateWithTime(unixTime, showHours = true, showWeekday = true) {
        if (!unixTime) return '-'
        const options: Intl.DateTimeFormatOptions = {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          weekday: undefined,
          ...(showHours
            ? {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
              }
            : {})
        }

        if (showWeekday) {
          options.weekday = 'short'
        }
        return new Date(unixTime * 1000).toLocaleDateString([], options)
      },
      /**
       * Using .toFixed(x) gives inaccurate results when rounding certain numbers
       * (0.2850 rounds to 0.28 instead of 0.29)
       *
       * This solution allows for correct rounding to match the results
       * received from Intl.NumberFormat when rounding currency
       */
      roundNumber(x, placesToRound) {
        return (+(Math.round(x + `e+${placesToRound}`) + `e-${placesToRound}`)).toFixed(2)
      },
      /**
       * Given some object, 'obj', this method will recurse through it at
       * most 'maxDepth' times, rounding any numbers found down to 'numPlaces'
       * decimal places.
       */
      roundRecursively(obj, currentDepth = 1, maxDepth = 10, numPlaces = 2) {
        if (currentDepth > maxDepth) {
          return obj
        }
        for (const key in obj) {
          if (typeof obj[key] === 'number') {
            const factor = Math.pow(10, numPlaces)
            obj[key] = Math.floor(obj[key] * factor) / factor
          } else if (typeof obj[key] === 'object') {
            this.roundRecursively(obj[key], ++currentDepth, maxDepth, numPlaces)
          }
        }
        return obj
      },
      roundMinDecimals(x, minDecimals, maxDecimals) {
        let num = typeof x === 'string' ? parseFloat(x) : x

        if (isNaN(num) || num === null) {
          return ''
        }

        let numStr = num.toFixed(maxDecimals)
        let [integerPart, decimalPart] = numStr.split('.')

        if (decimalPart) {
          decimalPart = decimalPart.slice(0, maxDecimals).replace(/0+$/, '')
          if (decimalPart.length < minDecimals) {
            decimalPart = decimalPart.padEnd(minDecimals, '0')
          }
        } else {
          decimalPart = '0'.repeat(minDecimals)
        }
        return `${integerPart}.${decimalPart}`
      },
      roundWithinThreshold(value: number, tolerance = 0.001) {
        const roundedValue = Math.round(value)
        if (Math.abs(value - roundedValue) <= tolerance) {
          return roundedValue
        }
        return value
      },
      timeDifference(previous, current) {
        current = current || Date.now() / 1000
        const sPerMinute = 60
        const sPerHour = sPerMinute * 60
        const sPerDay = sPerHour * 24
        const sPerMonth = sPerDay * 30
        const sPerYear = sPerDay * 365

        const elapsed = current - previous
        console.log('elapsed', elapsed)
        if (elapsed < sPerMinute) {
          const output = Math.round(elapsed / 1000)
          return output == 1 ? output + ' second ago' : output + ' seconds ago'
        } else if (elapsed < sPerHour) {
          const output = Math.round(elapsed / sPerMinute)
          return output == 1 ? output + ' minute ago' : output + ' minutes ago'
        } else if (elapsed < sPerDay) {
          const output = Math.round(elapsed / sPerHour)
          return output == 1 ? output + ' hour ago' : output + ' hours ago'
        } else if (elapsed < sPerMonth) {
          const output = Math.round(elapsed / sPerDay)
          return output == 1 ? output + ' day ago' : output + ' days ago'
        } else if (elapsed < sPerYear) {
          const output = Math.round(elapsed / sPerMonth)
          return output == 1 ? output + ' month ago' : output + ' months ago'
        } else {
          return Math.round(elapsed / sPerYear) + ' years ago'
        }
      },
      estimateSizeAndUnit(type, allSizeUnits, venue, ingredients, recipeUnitName?: string) {
        let venue_unit_name = recipeUnitName || ''
        if (!venue_unit_name) {
          if (type === 'food') {
            venue_unit_name = venue.default_weight || 'lb'
          } else {
            venue_unit_name = venue.default_volume || 'oz'
          }
        }

        const venue_unit = allSizeUnits.find(function (unit) {
          return unit.name.toLowerCase() === venue_unit_name
        })
        const unitType = type === 'food' ? 'weight' : 'volume'

        let size_value = 0

        const filteredSizeUnits = allSizeUnits.filter((unit) => unit.type === unitType)
        ingredients.forEach(function (ingredient) {
          const itemUnit = filteredSizeUnits.find(function (unit) {
            return unit.id === ingredient.size_units_id
          })
          // exclude "count" unit
          if (itemUnit && itemUnit.id != 8 && venue_unit) {
            if (itemUnit.id == venue_unit.id) {
              size_value = size_value + (parseFloat(ingredient.size_value) || 0)
            } else {
              size_value =
                size_value +
                (itemUnit['conv_to_' + venue_unit.name.toLowerCase()] * ingredient.size_value || 0)
            }
          }
        })
        return {
          size_value: this.formatQuantity(size_value),
          size_units_id: venue_unit.id
        }
      },
      getSizeUnitType(unitId, allSizeUnits) {
        const obj = allSizeUnits.find(function (unitObj) {
          return unitObj.id === unitId
        })

        if (obj) {
          return obj.type
        } else {
          return 'unknown'
        }
      },
      deepCopy(obj) {
        return JSON.parse(JSON.stringify(obj))
      },
      submitHubspotContactForm(portalId, formGuid, data) {
        const name = 'hubspotutk='
        const decodedCookie = decodeURIComponent(document.cookie)
        const ca = decodedCookie.split(';')
        let hutk
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i]
          while (c.charAt(0) == ' ') {
            c = c.substring(1)
          }
          if (c.indexOf(name) == 0) {
            hutk = c.substring(name.length, c.length)
          }
        }
        const body = Object.assign({}, data)

        if (hutk) {
          body['context'] = { hutk }
        }

        const url =
          'https://api.hsforms.com/submissions/v3/integration/submit/' + portalId + '/' + formGuid
        return fetch(url, {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(function (res) {
          return res.json()
        })
      },
      initDropdownMenu() {
        $('.dropdown-menu a.dropdown-toggle').on('mouseenter', function (e) {
          if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass('show')
          }
          const $subMenu = $(this).next('.dropdown-menu')
          $subMenu.toggleClass('show')

          $(this)
            .parents('li.nav-item.dropdown.show')
            .on('hidden.bs.dropdown', function (e) {
              $('.dropdown-submenu .show').removeClass('show')
            })

          return false
        })
        $(document).on('click', 'li .dropdown-menu', function (e) {
          e.stopPropagation()
        })
      },
      onVenueLoaded(callback, currentVeneId) {
        const thatVm = this
        if (currentVeneId) {
          // Profile is already loaded
          callback()
          return
        }
        const id = setInterval(() => {
          // Wailt until the profile is loaded
          if (currentVeneId) {
            clearInterval(id)
            callback()
          }
        }, 100)
      },
      getCountQuantity(count) {
        const quantity = count.quantity || 0
        const quantityFromCases = count.units_per_case * count.cases || 0
        return quantity + quantityFromCases
      },
      formatGoogleShortDate(unixTime) {
        if (!unixTime) return '-'
        const dateObj = new Date(unixTime * 1000)
        const year = dateObj.getFullYear()
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0') // Months are zero-indexed
        const day = dateObj.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`
      }
    }
  }
}
