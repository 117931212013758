<template>
  <div :class="['tag-container', tagStyle, status, additionalClass, tagSize]">
    <div @click="onTagClick" class="content">
      <i :style="iconStyle" v-if="icon" :class="[icon]"></i>
      <i
        :style="secondIconStyle"
        v-if="secondIcon && secondIcon?.length > 0"
        :class="[secondIcon]"
      ></i>
      <div
        v-if="label && tagSize !== TagSize.XSmall && tagSize !== TagSize.XXSmall"
        :class="['tag-label']"
      >
        {{ label }}
      </div>
      <div
        v-if="removable && tagSize !== TagSize.XSmall && tagSize !== TagSize.XXSmall"
        class="removable"
        @click="removeTag"
      >
        <i :class="['fa-solid fa-xmark']"></i>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { Icons, TagSize, TagStatuses, TagStyle } from '@/enums/components.enum'

export default defineComponent({
  name: 'Tags Component',
  props: {
    tagStyle: {
      type: String as PropType<TagStyle>,
      required: false,
      default: 'Default'
    },
    iconStyle: {
      type: Object
    },

    additionalClass: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String as PropType<Icons>,
      default: null,
      required: false
    },
    secondIcon: {
      type: String
    },
    secondIconStyle: {
      type: Object
    },
    removable: {
      type: Boolean,
      default: false,
      required: false
    },
    status: {
      type: String as PropType<TagStatuses>,
      default: null,
      required: false
    },
    tagSize: {
      type: String as PropType<TagSize>,
      default: '',
      required: false
    }
  },
  emits: ['onTagClick', 'removeTag'],
  methods: {
    removeTag() {
      this.$emit('removeTag')
    },
    onTagClick() {
      this.$emit('onTagClick')
    }
  },
  setup() {
    return {
      Icons,
      TagSize
    }
  }
})
</script>

<style scope>
.tag-container {
  padding-left: 8px;
  padding-right: 8px;
  background: #d8e2eb; /* decorative-tag-default */
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  align-self: center;
}

.tag-container.black {
  background: #313635;
}

.tag-container .content {
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
}

.tag-container i {
  text-align: center;
  color: #313635; /*icon-default*/
  font-size: 12px;
  line-height: 20px;
  font-weight: 900;
  word-wrap: break-word;
}

.tag-container.black i {
  color: #ffffff;
}

.tag-container.red-icon i {
  color: #ca3534;
}

.tag-container.green-icon i {
  color: #326f65;
}

.tag-container .content .tag-label {
  color: #0c1c19;
  font-size: 14px;
  font-family: Nunito;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}

.tag-container.black .content .tag-label {
  color: #ffffff;
}

.tag-container .content .removable {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.tag-container .content .removable:hover {
  background: #c3d2e0; /* decorative-tag-hover  */
}

.tag-container .content .removable:active {
  background: #b2c5d7; /* decorative-tag-pressed  */
}

.tag-container.warning {
  background: var(--color-tag-warning);
}

.tag-container.warning i {
  color: var(--icon-warning);
}

.tag-container.critical {
  background: #f9e3e3 /* $decorative-tag-critical */;
}

.tag-container.success {
  background: #ceeae4 /* $decorative-tag-success */;
}

.tag-container.small .content .tag-label {
  font-size: 12px;
  line-height: 16px;
}

.tag-container.small i {
  font-size: 10px;
  line-height: 16px;
}

.tag-container.xsmall {
  height: 14px;
  width: 14px;
}

.tag-container.xsmall i {
  font-size: 8px;
  line-height: 8px;
}

.tag-container.xxsmall {
  padding: 0px 6px;
  height: 10px;
  width: 16px;
}

.tag-container.xxsmall i {
  font-size: 6px;
  line-height: 6px;
}

.tag-container.posrel {
  position: absolute;
  top: 6px;
  left: 6px;
}
</style>
