<template>
  <div :class="['datepicker-input', classNames]">
    <VueDatePicker
      v-model="internalValue"
      :month-change-on-scroll="false"
      :time-picker-inline="showTimeSelection"
      :enable-time-picker="showTimeSelection"
      :is-24="false"
      :am-pm-buttom="showTimeSelection"
      :action-row="{ showSelect: false, showCancel: false, showNow: false, showPreview: false }"
      :day-names="['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']"
      week-start="0"
      auto-apply
      ref="datepicker"
      placeholder="Select a date"
      :format="format"
      persistent
      :clearable="false"
      :highlight="highlight"
      :min-date="minDate"
      :max-date="maxDate"
    >
      <template
        #dp-input="{
          value,
          onInput,
          onEnter,
          onTab,
          onClear,
          onBlur,
          onKeypress,
          onPaste,
          isMenuOpen
        }"
      >
        <div
          :class="[
            'calendar-group',
            dualPosition ? dualPosition : 'left',
            isMenuOpen ? 'active' : ''
          ]"
        >
          <i :class="['fas fa-calendar left-icon']"></i>
          <input
            type="text"
            :value="value"
            class="selectDateInput"
            placeholder="Select a date"
            readonly
          />
          <i class="fas fa-sort-down right-icon"></i>
        </div>
      </template>
      <template #arrow-left>
        <i class="fa-solid fa-chevron-left fa-sm"></i>
      </template>
      <template #arrow-right>
        <i class="fa-solid fa-chevron-right fa-sm"></i>
      </template>
      <template #input-icon>
        <i class="fa-solid fa-calendar pl-2 pr-2"></i>
      </template>
      <template #time-picker="{ time, updateTime }">
        <div class="time-component form-group">
          <select
            class="form-control d-inline-block initial-width"
            :value="displayedHour"
            @change="updateTimeHours($event.target.value, updateTime)"
          >
            <option v-for="h in hoursArray" :key="h.value" :value="h.value">{{ h.text }}</option>
          </select>
          <select
            class="form-control d-inline-block initial-width"
            :value="time.minutes"
            @change="updateTime(+$event.target.value, false)"
          >
            <option v-for="m in minutesArray" :key="m.value" :value="m.value">{{ m.text }}</option>
          </select>
          <select
            class="form-control d-inline-block initial-width"
            :value="isPm ? 'PM' : 'AM'"
            @change="toggleAmPm($event.target.value, updateTime)"
          >
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div>
      </template>
    </VueDatePicker>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import '@vuepic/vue-datepicker/dist/main.css'

export default defineComponent({
  name: 'Date Picker Component',
  props: {
    showTimeSelection: {
      type: Boolean,
      default: false,
      required: false
    },
    modelValue: {
      required: true,
      type: [Number, null]
    },
    dateOptions: {
      required: false,
      default: { year: 'numeric', month: 'short', day: 'numeric' }
    },
    highlight: {
      required: false,
      type: [Array<Date | null>, null],
      default: {}
    },
    minDate: {
      required: false,
      type: [Date, null],
      default: null
    },
    maxDate: {
      required: false,
      type: [Date, null],
      default: null
    },
    dualPosition: {
      required: false,
      type: String,
      default: 'left'
    },
    classNames: {
      required: false,
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const internalValue = ref(props.modelValue ? new Date(props.modelValue * 1000) : null)
    const isPm = ref(false)

    watch(internalValue, (newValue) => {
      if (newValue) {
        emit('update:modelValue', Math.floor(newValue.getTime() / 1000))
      } else {
        emit('update:modelValue', null)
      }
    })

    watch(
      () => props.modelValue,
      (newValue) => {
        internalValue.value = newValue ? new Date(newValue * 1000) : null
      }
    )

    const format = (date: Date | null) => {
      if (!date) return ''
      return date.toLocaleDateString('en-US', props.dateOptions)
    }

    const hoursArray = computed(() => {
      return [
        { text: 1, value: 1 },
        { text: 2, value: 2 },
        { text: 3, value: 3 },
        { text: 4, value: 4 },
        { text: 5, value: 5 },
        { text: 6, value: 6 },
        { text: 7, value: 7 },
        { text: 8, value: 8 },
        { text: 9, value: 9 },
        { text: 10, value: 10 },
        { text: 11, value: 11 },
        { text: 12, value: 0 }
      ]
    })

    const minutesArray = computed(() => {
      const arr = []
      for (let i = 0; i < 60; i++) {
        arr.push({ text: i < 10 ? `0${i}` : i, value: i })
      }
      return arr
    })

    const displayedHour = computed(() => {
      const currentHour = internalValue.value?.getHours() || 0
      if (currentHour === 0 || currentHour === 12) {
        return 0
      } else {
        return currentHour % 12
      }
    })

    const updateTimeHours = (newHour: string, updateTime: Function) => {
      let hour = +newHour
      if (isPm.value && hour < 12) {
        hour += 12
      } else if (!isPm.value && hour === 12) {
        hour = 0
      }
      updateTime(hour)
    }

    const toggleAmPm = (value: string, updateTime: Function) => {
      isPm.value = value === 'PM'
      const currentHour = internalValue.value?.getHours() || 0

      if (isPm.value && currentHour < 12) {
        updateTime(currentHour + 12)
      } else if (!isPm.value && currentHour >= 12) {
        updateTime(currentHour - 12)
      }
    }

    return {
      internalValue,
      format,
      hoursArray,
      minutesArray,
      displayedHour,
      toggleAmPm,
      updateTimeHours,
      isPm
    }
  }
})
</script>
<style>
.datepicker-input {
  border-color: #dbdfdf;
  border-style: solid;
  border-radius: 3px;
  border-width: 1px;
  box-shadow: 0px 1px 1px 0px rgba(219, 223, 223, 0.75);
  display: flex;
  align-items: center;
}

.dp__month_year_select {
  font-weight: 700;
}

.dp--header-wrap {
  border-bottom: 1px #ccd1d0 solid;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.dp__calendar_header_separator {
  display: none;
}

.dp__input {
  box-shadow: 0px 1px 1px 0px rgba(219, 223, 223, 0.75);
}

.dp__today {
  border-radius: 3px;
}

.dp__arrow_top {
  display: none;
}

.dp__menu {
  border: 1px #ccd1d0 solid;
  box-shadow: 0px 2px 4px 0px rgba(219, 223, 223, 0.75);
  border-radius: 3px;
}

.time-component {
  text-align: center;
  margin-bottom: 10px;
}

input.selectDateInput {
  border: none !important;
  border-radius: 3px;
  border-color: transparent !important;
  width: 80%;
  padding: 0.5rem;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input.selectDateInput {
  padding-left: 30px;
}

input.selectDateInput:hover {
  border: none;
}

input.selectDateInput:focus-visible {
  outline: none;
}

.calendar-group {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  z-index: 1; /* Ensure it is above the pseudo-elements */
}

.calendar-group.left::before,
.calendar-group.right::before {
  content: '';
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border: 1px solid #7d8888;
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: -1; /* Ensure the pseudo-elements are below the actual elements */
}

.calendar-group.left:hover::before,
.calendar-group.right:hover::before,
.calendar-group.left.active::before,
.calendar-group.right.active::before {
  opacity: 1;
}

.left-icon,
.right-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.left-icon {
  left: 10px;
}

.right-icon {
  right: 10px;
  top: 15px;
}

input::placeholder {
  color: var(--color-default-text);
}
</style>
