<template>
  <div class="calendar-wrapper dash-kpi p-2">
    <div class="d-flex align-items-center mb-2">
      <ButtonComponent @click="clickToday" :buttonType="ButtonTypes.Secondary" small
        >Today</ButtonComponent
      >
      <h2 class="ml-2">
        {{ selectedDate.format('MMMM YYYY') }}
      </h2>
    </div>

    <div class="days-row mb-2">
      <ButtonComponent @click="prevWeek" :buttonType="ButtonTypes.Secondary" round>
        <i class="fas fa-chevron-left"></i>
      </ButtonComponent>
      <div
        v-for="item in board"
        :key="item.id"
        @click="onClickDate(item.date)"
        :class="[
          'date-button',
          item.date.format('MM/DD/YYYY') == selectedDate.format('MM/DD/YYYY') &&
            'selected-date-button'
        ]"
      >
        <h4 class="text-center">
          {{ item.date.format('D') }}
          <sup v-if="!item.disabled" style="vertical-align: top; line-height: 1.5">
            <i class="fa fa-circle date-with-item-indicator-icon"></i>
          </sup>
        </h4>
        <p
          :class="[
            item.date.format('MM/DD/YYYY') == selectedDate.format('MM/DD/YYYY')
              ? 'underlined pb-1'
              : 'pb-2',
            'text-center'
          ]"
        >
          {{ item.date.format('ddd') }}
        </p>
      </div>
      <ButtonComponent @click="nextWeek" :buttonType="ButtonTypes.Secondary" round>
        <i class="fas fa-chevron-right"></i>
      </ButtonComponent>
    </div>

    <div
      :class="['card-wrapper', hideFooter ? 'hide-after' : '']"
      @scroll.passive="removeFooterOnScrollDown"
      ref="cardsWrapperDiv"
    >
      <div v-if="selectedDateItems.length > 0">
        <div v-for="item in selectedDateItems" :key="item.id + item.subtype" class="card mb-2">
          <p class="tag">{{ item.type }}</p>
          <h4 class="my-0" style="font-size: 14px" v-if="item.title">
            {{
              item.title +
              (item?.subtype === 'Otc cutoff'
                ? ` ${$utils.formatTimeNoSeconds(item.cutoffTime)}`
                : '')
            }}
          </h4>
          <div v-if="item.type === 'Shift Notes'">
            <div
              v-for="(invItem, index) in item.invItems.slice(0, 4)"
              :key="invItem"
              class="invitem-container"
            >
              <p>{{ invItem }}</p>
            </div>
            <p v-if="item.invItems.length > 4">+{{ item.invItems.length - 4 }} more</p>
          </div>
          <p v-if="item.summary">{{ item.summary }}</p>
          <div v-if="item.dataTable" class="row">
            <div v-for="(value, key) in item?.dataTable" :key="key">
              <div class="col">
                <p style="font-weight: 600">{{ key }}</p>
                <p>{{ formatDataTable(key, value) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="align-items-center mt-2">
        <h4 class="text-center">No activities on {{ selectedDate.format('MMMM D') }}</h4>
        <p class="text-center">
          Any updates to your inventory, orders, and more will be shown here.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useVendorsStore } from '@/stores/vendors'
import moment, { type Moment } from 'moment'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { ButtonTypes } from '@/enums/components.enum'

export default defineComponent({
  data() {
    return {
      board: [],
      calendarItems: {},
      selectedDateItems: [],
      startingDay: moment().startOf('week'), // Start on Sunday
      selectedDate: moment(),
      startDate: moment().subtract(15, 'days').unix(),
      endDate: moment().add(15, 'days').unix(),
      today: moment().format('MM/DD/YYYY'),
      hideFooter: true,
      ButtonTypes: ButtonTypes
    }
  },
  methods: {
    ...mapActions(useVendorsStore, ['getVendors']),
    ...mapState(useVendorsStore, ['owedVendors']),
    ...mapState(useUserStore, ['currentVenue']),
    makeBoard: function (startingDay) {
      const curDay = moment(startingDay),
        daysArr = [
          {
            disabled: true,
            date: startingDay,
            id: curDay.startOf('day').diff(moment().startOf('day'), 'days')
          }
        ]
      for (let i = 1; i < 7; i++) {
        // curDay is already mutated to the prev iteration day on above line
        daysArr.push({
          disabled: true,
          date: moment(curDay.add(1, 'day')),
          id: curDay.startOf('day').diff(moment().startOf('day'), 'days')
        })
      }
      return daysArr
    },
    disableDates() {
      const thatVm = this
      const { orders, sessions, inventoryItems, deliveryDates, nonOtcDeliveryDates } =
        thatVm.calendarItems
      const board = thatVm.board
      thatVm.board = board.map((item) => {
        const date = item.date.format('MM/DD/YYYY')
        if (
          date in orders ||
          date in sessions ||
          date in inventoryItems ||
          date in deliveryDates ||
          this.owedPayments?.[date] ||
          date in nonOtcDeliveryDates
        ) {
          return {
            ...item,
            disabled: false
          }
        }
        return item
      })
    },
    fetchCalendar() {
      $.get(`/calendar?start_date=${this.startDate}&end_date=${this.endDate}`)
        .done((data) => {
          if ('orders' in data) {
            this.calendarItems = data
            this.disableDates()
          }
        })
        .fail(function (data) {
          console.log('Failed to retrive calendar data:', data)
        })
    },
    nextWeek() {
      const thatVm = this
      const nextWeekStarterDay = moment(thatVm.startingDay.add(1, 'weeks'))
      thatVm.startingDay = nextWeekStarterDay
      thatVm.board = this.makeBoard(moment(nextWeekStarterDay))
      // if end date is in range of that week
      const unixEndingDate = moment(thatVm.board[6].date).unix()
      if (unixEndingDate > thatVm.endDate) {
        thatVm.startDate = moment(thatVm.board[0].date).subtract(2, 'days').unix()
        thatVm.endDate = moment.unix(thatVm.startDate).add(30, 'days').unix()
        this.fetchCalendar()
      } else {
        thatVm.disableDates()
      }
      this.onClickDate(this.selectedDate.add(7, 'days'))
    },
    prevWeek() {
      const thatVm = this
      const prevWeekStarterDay = moment(thatVm.startingDay.subtract(1, 'weeks')) // update variable to prev week starting day
      thatVm.startingDay = prevWeekStarterDay
      thatVm.board = this.makeBoard(moment(prevWeekStarterDay))
      // if start Date is in range of that week
      const unixStartingDate = moment(thatVm.board[0].date).unix()
      if (unixStartingDate < thatVm.startDate) {
        thatVm.startDate = moment.unix(unixStartingDate).subtract(30, 'days').unix()
        thatVm.endDate = moment.unix(unixStartingDate).add(8, 'days').unix()
        this.fetchCalendar()
      } else {
        thatVm.disableDates()
      }
      this.onClickDate(this.selectedDate.subtract(7, 'days'))
    },
    onClickDate: function (clickedDate: Moment) {
      this.selectedDate = moment(clickedDate)
    },
    clickToday() {
      const thatVm = this
      thatVm.startingDay = moment().startOf('week')
      thatVm.board = thatVm.makeBoard(this.startingDay)
      ;(thatVm.startDate = moment().subtract(28, 'days').unix()),
        (thatVm.endDate = moment().add(28, 'days').unix())
      thatVm.fetchCalendar()
      this.onClickDate(moment())
    },
    formatDataTable(key: string, value: string) {
      if (['amount', 'total cost'].includes(key.toLowerCase())) {
        return this.$utils.formatCurrency(Number(value), this.currentVenue?.currency)
      } else {
        return value
      }
    },
    removeFooterOnScrollDown() {
      const calWrapperDiv: HTMLDivElement = this.$refs.cardsWrapperDiv as HTMLDivElement
      const isAtBottom =
        calWrapperDiv.scrollHeight - calWrapperDiv.clientHeight <= calWrapperDiv.scrollTop + 1
      this.hideFooter = isAtBottom
    }
  },
  async created() {
    this.board = this.makeBoard(this.startingDay)
    this.calendarItems = await $.get(
      `/calendar?start_date=${this.startDate}&end_date=${this.endDate}`
    )
    await this.getVendors()
    this.disableDates()
    this.onClickDate(moment())
  },
  computed: {
    owedPayments() {
      const paymentsForBoard = {}
      this.owedVendors().forEach((v) => {
        v.invoices!.forEach((invoice) => {
          if (
            invoice.balance > 0 &&
            invoice.due_date < this.endDate &&
            invoice.due_date > this.startDate
          ) {
            paymentsForBoard[moment.unix(invoice.due_date).format('MM/DD/YYYY')] = [
              {
                id: invoice.invoice_id,
                type: 'Payment',
                title: `Payment to ${v.name} is due`,
                dataTable: { Amount: invoice.balance, Invoice: invoice.invoice_number }
              }
            ]
          }
        })
      })

      return paymentsForBoard
    }
  },
  watch: {
    selectedDate() {
      const formattedSelectedDate = this.selectedDate.format('MM/DD/YYYY')
      const orders = this.calendarItems?.orders[formattedSelectedDate] || []
      const sessions = this.calendarItems?.sessions[formattedSelectedDate] || []
      const invItems = this.calendarItems?.inventoryItems[formattedSelectedDate] || []
      const duePayments = this.owedPayments?.[formattedSelectedDate] || []
      const deliveries = this.calendarItems?.deliveryDates[formattedSelectedDate] || []
      const nonOtcDeliveries = this.calendarItems?.nonOtcDeliveryDates[formattedSelectedDate] || []
      this.selectedDateItems = [
        ...orders,
        ...sessions,
        ...invItems,
        ...deliveries,
        ...duePayments,
        ...nonOtcDeliveries
      ]

      this.$nextTick(() => {
        const calWrapperDiv: HTMLDivElement = this.$refs.cardsWrapperDiv as HTMLDivElement
        if (calWrapperDiv) {
          const isTooLittleContent = calWrapperDiv.scrollHeight <= calWrapperDiv.clientHeight
          this.hideFooter = isTooLittleContent
        }
      })
    }
  }
})
</script>
